import * as React from "react"

/**
 * Generic index page but redirects.
 *
 * @class
 */
const Index: React.FunctionComponent = () => {
  if (typeof window !== "undefined") {
    window.location.href = "/app/login"
  }
  return <p>loading...</p>
}

export default Index
